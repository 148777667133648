
const translations = {
    english: {
      timeTracker: 'Time Tracker',
      overview: 'Overview',
      worker: 'Worker',
      addTime: 'Add Time',
      order: 'Order',
      updateDrinks: 'Update Drinks',
      overviewProducts: 'Overview Products',
      toggleLanguage: 'Toggle Language',
      logOut: 'Log Out',
    },
    greek: {
      timeTracker: 'Χρονομετρητής',
      overview: 'Επισκόπηση',
      worker: 'Εργαζόμενος',
      addTime: 'Προσθήκη Χρόνου',
      order: 'Παραγγελία',
      updateDrinks: 'Ενημέρωση Ποτών',
      overviewProducts: 'Επισκόπηση Προϊόντων',
      toggleLanguage: 'Αλλαγή Γλώσσας',
      logOut: 'Αποσύνδεση',
    },
    dutch: {
      timeTracker: 'Tijd Tracker',
      overview: 'Overzicht',
      worker: 'Werknemer',
      addTime: 'Tijd Toevoegen',
      order: 'Bestelling',
      updateDrinks: 'Dranken Updaten',
      overviewProducts: 'Overzicht Producten',
      toggleLanguage: 'Taal Wisselen',
      logOut: 'Uitloggen',
    }
};

export default translations;