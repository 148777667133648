import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const OrderPageWrapper = styled.div`
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
`;

const RoomCard = styled.div`
  background-color: #fff;
  color: #000;
  width: 200px;
  height: 150px;
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const RoomNumber = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const RoomName = styled.p`
  font-size: 14px;
`;

const OrderPage = () => {
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const response = await fetch('http://localhost:5000/api/rooms');
      const data = await response.json();
      setRooms(data);
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  return (
    <>
      <Title>Order Page</Title>
      <OrderPageWrapper>
        {rooms.map(room => (
          <RoomCard key={room.id}>
            <RoomNumber>Room {room.roomNumber}</RoomNumber>
            <RoomName>{room.name}</RoomName>
          </RoomCard>
        ))}
      </OrderPageWrapper>
    </>
  );
};

export default OrderPage;
