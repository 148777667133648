import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Sidebar';
import OrderPage from './Components/OrderPage';
import './App.css';

//temp components
const TimeTrackerOverview = () => <div>Time Tracker Overview Page</div>;
const TimeTrackerWorker = () => <div>Time Tracker Worker Page</div>;
const TimeTrackerAddTime = () => <div>Time Tracker Add Time Page</div>;

const OrderUpdateDrinks = () => <div>Order Update Drinks Page</div>;
const OrderOverviewProducts = () => <div>Order Overview Products Page</div>;


const App = () => {
  const [currentLanguage, setCurrentLanguage] = useState('english');

  const toggleLanguage = (active) => {
    setCurrentLanguage(active);
  };

  return (
    <Router>
      <div className='flex bg-[#f5f5f5]'>
        <Sidebar toggleLanguage={toggleLanguage} currentLanguage={currentLanguage} />
        <div className='flex-1 overflow-y-auto'>
          <Routes>
            <Route path="/" element={<h1>Homepage</h1>} />
            <Route path="/timetracker/overview" element={<TimeTrackerOverview />} />
            <Route path="/timetracker/worker" element={<TimeTrackerWorker />} />
            <Route path="/timetracker/addtime" element={<TimeTrackerAddTime />} />

            <Route path="/order/order" element={<OrderPage />} />
            <Route path="/order/updatedrinks" element={<OrderUpdateDrinks />} />
            <Route path="/order/overviewproducts" element={<OrderOverviewProducts />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
