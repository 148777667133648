import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineCaretDown, AiOutlineCaretRight, AiOutlineDoubleRight, AiOutlineDoubleLeft } from 'react-icons/ai';
import translations from '../Translations';

const SidebarLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  font-weight: ${props => (props.$subItem ? 'normal' : 'bold')};
  border-left: 3px solid transparent;
  padding-left: 10px;

  &:hover {
    color: #ccc;
  }

  &.active {
    font-weight: bold;
    border-color: #fff;
  }
`;

const IconWrapper = styled.span`
  margin-right: 8px;
`;

const SubMenuWrapper = styled.div`
  margin-left: 20px;
`;

const CollapseButton = styled.button`
  position: absolute;
  top: 15px;
  right: ${props => (props.collapsed ? '0' : '10px')};
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: right 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const Sidebar = ({ currentLanguage, toggleLanguage }) => {
  const [timeTrackerSubMenuOpen, setTimeTrackerSubMenuOpen] = useState(false);
  const [orderSubMenuOpen, setOrderSubMenuOpen] = useState(false);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [languageMenuOpen, setLanguageSubMenuOpen] = useState(false);

  const toggleLanguageItem = () => {
    toggleLanguage();
    setLanguageSubMenuOpen(false);
  };
  const toggleTimeTrackerSubMenu = () => {
    setTimeTrackerSubMenuOpen(!timeTrackerSubMenuOpen);
  };

  const toggleOrderSubMenu = () => {
    setOrderSubMenuOpen(!orderSubMenuOpen);
  };

  const toggleLanguageSubMenu = () => {
    setLanguageSubMenuOpen(!languageMenuOpen);
  };

  const toggleSidebar = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };

  return (
    <div className={`bg-gray-800 text-white ${sidebarCollapsed ? 'w-[20px]' : 'w-[260px]'} h-screen flex flex-col items-start p-6 transition-width duration-300 ease-in-out relative`}>
      <CollapseButton $collapsed={sidebarCollapsed} onClick={toggleSidebar}>
        {sidebarCollapsed ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}
      </CollapseButton>
      {!sidebarCollapsed && (
        <>
          <SidebarLink onClick={toggleTimeTrackerSubMenu}>
            <IconWrapper>{timeTrackerSubMenuOpen ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}</IconWrapper>
            {translations[currentLanguage].timeTracker}
          </SidebarLink>
          <SubMenuWrapper>
            {timeTrackerSubMenuOpen && (
              <>
                <SidebarLink to="/timetracker/overview" $subItem>
                  {translations[currentLanguage].overview}
                </SidebarLink>
                <SidebarLink to="/timetracker/worker" $subItem>
                  {translations[currentLanguage].worker}
                </SidebarLink>
                <SidebarLink to="/timetracker/addtime" $subItem>
                  {translations[currentLanguage].addTime}
                </SidebarLink>
              </>
            )}
          </SubMenuWrapper>
          <SidebarLink onClick={toggleOrderSubMenu}>
            <IconWrapper>{orderSubMenuOpen ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}</IconWrapper>
            {translations[currentLanguage].order}
          </SidebarLink>
          <SubMenuWrapper>
            {orderSubMenuOpen && (
              <>
                <SidebarLink to="/order/order" $subItem>
                  {translations[currentLanguage].order}
                </SidebarLink>
                <SidebarLink to="/order/updatedrinks" $subItem>
                  {translations[currentLanguage].updateDrinks}
                </SidebarLink>
                <SidebarLink to="/order/overviewproducts" $subItem>
                  {translations[currentLanguage].overviewProducts}
                </SidebarLink>
              </>
            )}
          </SubMenuWrapper>
          <SidebarLink onClick={toggleLanguageSubMenu}>
          <IconWrapper>{languageMenuOpen ? <AiOutlineCaretDown /> : <AiOutlineCaretRight />}</IconWrapper>
            {translations[currentLanguage].toggleLanguage}
            </SidebarLink>
          <SubMenuWrapper>
            {languageMenuOpen && (
              <>
                <button onClick={() => toggleLanguage('english')} className={currentLanguage === "english"? 'buttonLang active':"buttonLang"}>
                  English
                </button>
                <button onClick={() => toggleLanguage('greek')} className={currentLanguage === "greek"? 'buttonLang active':"buttonLang"}>
                  Greek
                </button>
                <button onClick={() => toggleLanguage('dutch')} className={currentLanguage === "dutch"? 'buttonLang active':"buttonLang"}>
                  Dutch
                </button>
              </>
            )}
          </SubMenuWrapper>
          <SidebarLink>{translations[currentLanguage].logOut}</SidebarLink>
        </>
      )}
    </div>
  );
};

export default Sidebar;
